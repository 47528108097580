import {onSlide} from "./bar-steps-iterator";
import {canSendToSMS, canUseNativeShare, email_share_site, nativeShare, sendToSMS} from "./share-logic";

function stateCheck(){
    const make_dd = $("#select-make");
    const model_dd = $("#select-model");
    const zipcode_txt = $("#zipcode");
    const button = $("#go-to-copilot-compare");

    const make = $("#select-make > option:selected").val();
    const model = $("#select-model > option:selected").val();
    const zipcode = $("#zipcode").val();

    if (make == "Select Make"){
        model_dd.prop("disabled", true);
        button.prop("disabled", true).addClass("disabled");
    }else{
        model_dd.prop("disabled", false);
    }

    if (model == "Select Model"){
        button.prop("disabled", true).addClass("disabled");
    }else{
        zipcode_txt.prop("disabled", false);
    }

    const zip_pattern = /^\d{5}$|^\d{5}-\d{4}$/;

    if (zip_pattern.test(zipcode) && make != "Select Make" && model != "Select Model") {
        $("#go-to-copilot-compare").prop("disabled", false).removeClass("disabled");
    }else{
        $("#go-to-copilot-compare").prop("disabled", true).addClass("disabled");
    }

}
export function compareHandler(){
    // initial state check in case you came back to the page
    stateCheck();

    var options = [];
    $("#select-model > option").each(function() {
        options.push({model: $(this).val(), make: $(this).attr("dt-make")});
    });

    $("#select-make").val("Select Make").change();
    $("#select-model").prop("disabled", true);

    $("#select-make").prop("disabled", false);

    $("#select-make").change(function() {
        stateCheck();

        $("#select-model").val("Select Model").change();

        var selection = $('option:selected', this).val();
        if (selection != "Select Make") {

            /*
            * Certain Browsers do not allow you to hide disabled options
            * instead, we append all options to "options", then iterate over them adding/removing
            * them from the dom as needed
            */

            $("#select-model > option").each(function() {
                $(this).remove();
            });
            for (var i in options){
                const opt = options[i];
                if (opt.make == "default" || opt.make==selection){
                    $("#select-model")
                        .append($('<option></option>')
                        .attr("dt-make",opt.make)
                        .text(opt.model));
                }
            }
        }
    });

    $("#select-model").change(function() {
        stateCheck();
    });

    $("#zipcode").bind('input', function() {
        stateCheck();
    });

    // Check to see if we are in copilot-compare, if so send out a track call
    if (window.location.href.includes("copilot-compare")){
        if (!!analytics && !!analytics.track){
            analytics.track("copilotcompare_public_landing_loaded_valet")
        }
    }

    // FORM CTA
    $("#go-to-copilot-compare").click(function(){
        $("#go-to-copilot-compare").prop("disabled", true).addClass("disabled");
        setTimeout(function(){
            $("#go-to-copilot-compare").prop("disabled", false).removeClass("disabled");
        }, 1000);

        const make = $("#select-make > option:selected").val();
        const model = $("#select-model > option:selected").val();
        const zipcode = $("#zipcode").val();

        var uri = APP_CNAME + ".copilotsearch.com";
        const cur_url = window.location.href;
        if (cur_url.includes("localhost")){
            uri = "dcw.vagrant.test.ngrok.io";
        }

        analytics.track("copilot_compare_landing_page_submit_valet_site")

        window.location = "https://" + uri + "/navigator/#/copilot-compare/meet?make=" + make + "&model=" + model + "&zip=" + zipcode;
    });

    //  TESTIMONIAL SLIDER

    const number_of_testimonial = $(".cc-flexslider .slides .slide:not(clone)").length
    const bar_element = $(".bar-steps-iterator-container");


    $(".cc-flexslider").flexslider({
        animation: "slide",
        controlNav: false,
        directionNav: false,
        start: function(slider){ return onSlide(bar_element, 0, number_of_testimonial)},
        before: function(slider){ return onSlide(bar_element, getNextSlide(slider), number_of_testimonial)}
    });

    // IM READY NOW BUTTON
    $("#im-ready-now").click(function(){
        scrollToForm();
    });

    $("#close-sharing-modal").click(function(){
        $("#white-share-modal").css('display', 'none');
    })
    if(!canUseNativeShare()){
        // $("#copilot-compare-share-icon").css("display", "none");
        $("#white-share-modal").css("display", "none");

        if (!canSendToSMS()){
            $("#text-message-share-option").css("display", "none");
            $("#copilot-compare-share-icon").click(function() {
                if (analytics && !!analytics.track){
                    analytics.track("Clicked_Sharing_Button", {"share_location": "v-cclp", "what_shared": "copilot_compare", "share_medium": "web"})
                }
                email_share_site("valet_landing",
                    null,
                    "Don't miss out!",
                    "Check out this cool new app! It identifies surprisingly great buys (because of the economic crisis) on nearly new cars - you can save tens of thousands vs. buying new. You’ll definitely want to at least check them out now so you don’t end up paying more later. https://www.copilotsearch.com/copilot-compare/?utm_campaign=ccvs&utm_medium=share&utm_source=valet",
                    null, false);
            })
        }else{
            $("#copilot-compare-share-icon").click(function(){
                $("#white-share-modal").css("display", "block");
                if (analytics && !!analytics.track){
                    analytics.track("Clicked_Sharing_Button", {"share_location": "v-cclp", "what_shared": "copilot_compare", "share_medium": "web"})
                }
            })
            $("#text-message-share-option").click(function(){
                sendToSMS("Check out this cool new app! It " +
                    "identifies surprisingly great buys " +
                    "(because of the economic crisis) on " +
                    "nearly new cars - you can save tens of " +
                    "thousands vs. buying new. You’ll " +
                    "definitely want to at least check them " +
                    "out now so you don’t end up " +
                    "paying more later. " +
                    "https://www.copilotsearch.com/copilot-compare/?utm_campaign=ccvs&utm_medium=share&utm_source=valet");
            })
        }

        $("#email-share-option").click(function(){

            email_share_site("valet_landing",
                null,
                "Don't miss out!",
                "Check out this cool new app! It identifies surprisingly great buys (because of the economic crisis) on nearly new cars - you can save tens of thousands vs. buying new. You’ll definitely want to at least check them out now so you don’t end up paying more later. https://www.copilotsearch.com/copilot-compare/?utm_campaign=ccvs&utm_medium=share&utm_source=valet",
                null, false);
        });

    }else{

        $("#copilot-compare-share-icon").click(
            ccnativeShare.bind(this)
        );
    }

}

function ccnativeShare(){
    if (analytics && !!analytics.track){
        analytics.track("Clicked_Sharing_Button", {"share_location": "v-cclp", "what_shared": "copilot_compare", "share_medium": "web"})
    }
    nativeShare("CoPilot Compare", "Don't miss out! I found this cool app that shows you how you can save thousands by buying a nearly new car instead of a new one. Check it out here.", "https://www.copilotsearch.com/copilot-compare/?utm_campaign=ccvs&utm_medium=share&utm_source=valet")
}

function getNextSlide(slider){
    const SLIDER_DIRECTION = {NEXT: "next", PREV: "prev"};
    const total = slider.count;

    if (slider.direction == SLIDER_DIRECTION.NEXT){
        return (slider.currentSlide + 1) % total;
    }
    // clamp between 0 and total where -1 wraps to count - 1
    return [...Array(total).keys()][(total + (slider.currentSlide - 1)) % total ];
}

function scrollToForm(){
    window.scrollTo(0, 1600);
}