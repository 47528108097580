import { compareHandler } from './copilot-compare';


function documentReadyHandler(){

    // On load set all the text boxes labels correctly
    $('.custom-js-text select').map(function() {
        var selection = $('option:selected', this).val();
        const selected_text = $(this).siblings('.selected-item');
        selected_text.text(selection);
    });

    $('.custom-js-text select').change(function() {
        var selection = $('option:selected', this).val();
        const selected_text = $(this).siblings('.selected-item');
        selected_text.text(selection);
    });

    compareHandler();
}

$(document).ready(function (event) {
    documentReadyHandler();
});