export function isOnNativeAndroidApp(){
    try{
        const native = window.CopilotNativeApp;
        if (native && typeof native.event === 'function'){
            return true;
        }
        return false;
    }catch{
        return false;
    }

}

export function isOnNativeIOSApp(){
    try{
        const native = window.webkit.messageHandlers.CopilotNativeApp;
        if (native && typeof native.postMessage === 'function') {
            return true;
        }
        return false;
    }catch{
        return false;
    }

}
export function isOnNativeApp(){
    if (isOnNativeAndroidApp() || isOnNativeIOSApp()){
        return true;
    }
    return false;
}

// Iphone safari browser
export function IPhoneSafari(){
    return navigator.userAgent.match(/(iPhone)/) && navigator.userAgent.match(/AppleWebKit/);
}

export function AndroidChrome(){
    var ua = navigator.userAgent;
    return ((ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1) && (ua.indexOf('Version') > -1));
}