// native sharing logic

import {isOnNativeApp} from "./user-agents";

export function nativeShare(title, text, url) {
    if (canUseNativeShare()){
        navigator.share( { title: title, text: text, url: url} );
    }else if(isOnNativeApp()){
        window.location.href = "gonative://share/sharePage?text="+text+"&url=" + encodeURIComponent(url);
    }
}

export function canUseNativeShare() {
    try{
        return isOnNativeApp() || (!!navigator && navigator.share);
    }catch{
        return false;
    }
}

export const canSendToSMS = () => {
    return navigator.userAgent.match(/Android/i) ||navigator.userAgent.match(/iPhone/i)
}

export const sendToSMS = (text) => {
    if (canSendToSMS()){
        window.open(`sms://&body=${encodeURIComponent(text)}`,'_self')
    }
}


export function email_share_site(
    sharedFrom,
    image=null,
    title="I’m getting insider car pricing and inventory data using CoPilot!",
    description="If you’re in the market for a car you should definitely check this out",
    close_fn,
    include_purchase_with_confidence=true
) {
    const origin = "https://www.copilotsearch.com"


    //
    const new_line = "%0A"


    const share_url = "subject=" + title + "&" +
        "body=" + description + "" +
        new_line +
        new_line +
        new_line +
        (include_purchase_with_confidence ? (origin + "/purchase-with-confidence-doublecta?utm_source=email_share&utm_medium=bought_car&referrer=email" + new_line) : '') +
        (image ? image : "")

    const url = 'mailto:?' + share_url

    document.location = url


    if (close_fn && typeof close_fn === "function")
        close_fn()
}